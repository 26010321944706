import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";
import Vue from 'vue';
import { ErrorLogModule } from '@/store/modules/error-log';
import { isArray } from '@/utils/validate';
import settings from '@/settings';
var needErrorLog = settings.errorLog;

var checkNeed = function checkNeed() {
  var env = process.env.NODE_ENV;

  if (isArray(needErrorLog) && env) {
    return needErrorLog.includes(env);
  }

  return false;
};

if (checkNeed()) {
  Vue.config.errorHandler = function (err, vm, info) {
    ErrorLogModule.AddErrorLog({
      err: err,
      vm: vm,
      info: info,
      url: window.location.href
    });
  };
}