import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var reviewsRouter = {
  path: '/reviews',
  component: Layout,
  redirect: 'noredirect',
  name: 'review.title',
  meta: {
    title: 'review.title',
    roles: ['superadmin', 'admin'],
    icon: 'email'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import(
      /* webpackChunkName: "admins-list" */
      '@/views/reviews/list.vue');
    },
    name: 'review.title',
    meta: {
      title: 'review.title',
      icon: 'email',
      noCache: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/reviews/manage.vue');
    },
    name: 'review.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'review.edit',
      noCache: true,
      activeMenu: '/reviews/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: function component() {
      return import('@/views/reviews/manage.vue');
    },
    name: 'review.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'review.add',
      noCache: true,
      activeMenu: '/reviews/list',
      hidden: true
    }
  }]
};
export default reviewsRouter;