/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'tree-table': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M44.8 0h79.5c2.5 0 3.7 1.4 3.7 4.3v23.4c0 2.9-1.2 4.3-3.7 4.3H44.8c-2.4 0-3.7-1.4-3.7-4.3V4.3c0-2.9 1.3-4.3 3.7-4.3zm22.9 48h56.6c2.5 0 3.7 1.4 3.7 4.3v23.4c0 2.9-1.2 4.3-3.7 4.3H67.7c-2.5 0-3.7-1.4-3.7-4.3V52.3c0-2.9 1.2-4.3 3.7-4.3zm0 48h56.6c2.5 0 3.7 1.4 3.7 4.3v23.4c0 2.9-1.2 4.3-3.7 4.3H67.7c-2.5 0-3.7-1.4-3.7-4.3v-23.4c0-2.9 1.2-4.3 3.7-4.3zM50.3 68.3c2 0 3.6-2 3.6-4.3 0-2.4-1.6-4.3-3.6-4.3h-33V32h6.5c2 0 3.6-2 3.6-4.3V4.3c0-2.4-1.6-4.3-3.6-4.3H3.7C1.7 0 0 2 0 4.3v23.4C0 30.1 1.6 32 3.7 32H10v80c0 2.4 1.6 4.3 3.6 4.3h36.6c2 0 3.6-2 3.6-4.3 0-2.4-1.6-4.3-3.6-4.3h-33V68.3h33z"/>'
  }
});