import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.join";
import "core-js/modules/es.date.to-string";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.entries";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _slicedToArray from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _createForOfIteratorHelper from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _typeof from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/typeof";
// Parse the time to string
export var parseTime = function parseTime(time, cFormat) {
  if (time === undefined) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var timeStr = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      return '0' + value;
    }

    return String(value) || '0';
  });
  return timeStr;
};
export var formatJson = function formatJson(filterKeys, jsonData) {
  var out = [];
  var c = 0;

  var _iterator = _createForOfIteratorHelper(jsonData),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var data = _step.value;
      var dataExport = [];

      var _iterator2 = _createForOfIteratorHelper(filterKeys),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var key = _step2.value;

          if (['createdTimestamp', 'timestamp', 'orderTimestamp'].indexOf(key) > -1) {
            dataExport.push(parseTime(data[key]));
          } else if (key === 'enabled') {
            dataExport.push(data[key] ? 'enabled' : 'disabled');
          } else if (key.indexOf('.') > 0) {
            dataExport.push(getDescendantProp(data, key));
          } else if (key === 'balance') {
            var diff = data.grandTotal - data.amountReceived;

            if (diff < 0) {
              dataExport.push(0);
            } else {
              dataExport.push(diff);
            }
          } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
            // var products = ''
            // for (var i = 0; i < data.ordersProduct.length; i++) {
            //   if (data.ordersProduct[i].product) {
            //     products += `${data.ordersProduct[i].product.name}`
            //   }
            // }
            dataExport.push('');
          } else if (key === 'address' || key === 'shippingAddress') {
            var addressObj = key === 'address' ? data.address : data.shippingAddress;

            if (addressObj) {
              var address = [addressObj.line1, addressObj.line2];

              if (addressObj.city) {
                address.push(addressObj.city.name);
              }

              if (addressObj.state) {
                address.push(addressObj.state.name);
              }

              if (addressObj.country) {
                address.push(addressObj.country.name);
              }

              if (addressObj.pincode) {
                address.push(addressObj.pincode.pincode);
              }

              dataExport.push(address.filter(Boolean).join(',  '));
            } else {
              dataExport.push('');
            }
          } else {
            dataExport.push(data[key]);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      if (filterKeys.includes('products') && 'ordersProduct' in data) {
        var _iterator3 = _createForOfIteratorHelper(data.ordersProduct),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var prod = _step3.value;
            var temp = [].concat(dataExport);

            if (prod.product) {
              temp[11] = prod.product.name;
            }

            temp[12] = prod.quantity;
            temp[13] = +prod.salePrice > 0 ? prod.salePrice : prod.unitPrice;
            temp[14] = prod.subTotal;
            temp[15] = prod.taxedAmount;
            out.push(temp);
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      } else {
        out.push(dataExport);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return out;
}; // Format and filter json data using filterKeys array
// export const formatJson = (filterKeys: any, jsonData: any) =>
//    jsonData.map((data: any) => filterKeys.map((key: string) => {
//     if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
//       return parseTime(data[key])
//     } else if (key === 'enabled') {
//       return data[key] ? 'enabled' : 'disabled'
//     } else if (key.indexOf('.') > 0) {
//       return getDescendantProp(data, key)
//       // return data[key.substr(0, key.indexOf('.'))][key.substr(key.indexOf('.') + 1)]
//     } else if (key === 'balance') {
//       const diff = data.grandTotal - data.amountReceived
//       if (diff < 0) {
//         return 0
//       } else {
//         return diff
//       }
//     } else if (key === 'products') {
//       var products = ''
//       for (var i = 0; i < data.ordersProduct.length; i++) {
//         if (data.ordersProduct[i].product) {
//           products += `${data.ordersProduct[i].product.name}`
//         }
//       }
//       return products
//     }
//     else if (key === 'address') {
//       var addressObj = data.address
//       if(addressObj){
//       var address = [addressObj.line1,addressObj.line2]
//       if(addressObj.pincode){
//         address.push(addressObj.pincode.name)
//       }
//       if(addressObj.city){
//         address.push(addressObj.city.name)
//       }
//       if(addressObj.state){
//         address.push(addressObj.state.name)
//       }
//       if(addressObj.country){
//         address.push(addressObj.country.name)
//       }
//       address.filter(Boolean).join(",  ");
//       return address
//       }
//       else{
//         return ''
//       }
//     }
//     else {
//       return data[key]
//     }
//   })
//   )
// Check if an element has a class

export var hasClass = function hasClass(ele, className) {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}; // Add class to element

export var addClass = function addClass(ele, className) {
  if (!hasClass(ele, className)) ele.className += ' ' + className;
}; // Remove class from element

export var removeClass = function removeClass(ele, className) {
  if (hasClass(ele, className)) {
    var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}; // Toggle class for the selected element

export var toggleClass = function toggleClass(ele, className) {
  if (!ele || !className) {
    return;
  }

  var classString = ele.className;
  var nameIndex = classString.indexOf(className);

  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }

  ele.className = classString;
};
export var getQuery = function getQuery(query) {
  var processedQuery = Object.assign({}, query); // set filter as empty

  processedQuery.filter = []; // check for filter

  if ('filter' in query) {
    // loop over fields and check for values
    Object.entries(query.filter).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          field = _ref2[0],
          condition = _ref2[1];

      // check if field value has be set
      if (field in query && [undefined, ''].indexOf(query[field]) === -1) {
        processedQuery.filter.push(field + '||' + condition + '||' + processedQuery[field]);
        delete processedQuery[field];
      }
    });
  }

  return processedQuery;
};
export var getDescendantProp = function getDescendantProp(obj, desc) {
  var arr = desc.split('.');

  while (arr.length && (obj = obj[arr.shift()])) {
    ;
  }

  return obj;
};