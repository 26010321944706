import "core-js/modules/es.object.to-string";
export var pincodesSubMenu = [{
  path: '/pincode/list/:id',
  component: function component() {
    return import('@/views/pincode/list.vue');
  },
  name: 'Pincode',
  meta: {
    title: 'customerManagement.pincode.title',
    hidden: true,
    noCache: true
  }
}, {
  path: '/pincode/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/pincode/manage.vue');
  },
  name: 'customerManagement.pincode.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'customerManagement.pincode.manage',
    noCache: true,
    activeMenu: '/pincode/list',
    hidden: true
  }
}, {
  path: '/pincode/add/:id',
  component: function component() {
    return import('@/views/pincode/manage.vue');
  },
  name: 'customerManagement.pincode.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'customerManagement.pincode.manage',
    noCache: true,
    activeMenu: '/pincode/list',
    hidden: true
  }
}];