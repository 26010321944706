import "core-js/modules/es.object.to-string";
export var measurementUnitSubMenu = [{
  path: '/measurement-units/list',
  component: function component() {
    return import('@/views/measurement-unit/list.vue');
  },
  name: 'Measurement Unit',
  meta: {
    title: 'master.measurementunits.title',
    noCache: true,
    icon: 'scale'
  }
}, {
  path: '/measurement-units/add',
  component: function component() {
    return import('@/views/measurement-unit/manage.vue');
  },
  name: 'Add Measurement Unit',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.measurementunits.manage',
    noCache: true,
    activeMenu: '/measurement-units/list',
    hidden: true
  }
}, {
  path: '/measurement-units/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/measurement-unit/manage.vue');
  },
  name: 'Edit Measurement Unit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.measurementunits.manage',
    noCache: true,
    activeMenu: '/measurement-units/list',
    hidden: true
  }
}];