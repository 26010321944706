import "core-js/modules/es.object.to-string";
export var ordersDispatchesSubMenu = [{
  path: '/orders-dispatches/list/:orderId(.*)',
  component: function component() {
    return import('@/views/orders-dispatches/list.vue');
  },
  name: 'Dispatch',
  meta: {
    title: 'orderManagement.dispatch.title',
    roles: ['superadmin', 'admin', 'Order Manager'],
    noCache: true,
    hidden: true
  }
}, {
  path: '/orders-dispatches/add/:orderId(.*)',
  component: function component() {
    return import('@/views/orders-dispatches/manage.vue');
  },
  name: 'orderManagement.dispatch.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.dispatch.add',
    roles: ['superadmin', 'admin', 'Order Manager'],
    noCache: true,
    activeMenu: '/orders-dispatches/list/:orderId(.*)',
    hidden: true
  }
}];