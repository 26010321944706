// You can customize below settings :)
var settings = {
  title: 'Biggest Supplier Admin',
  showSettings: true,
  showTagsView: true,
  fixedHeader: false,
  showSidebarLogo: true,
  errorLog: ['production'],
  sidebarTextTheme: false,
  devServerPort: 9527
};
export default settings;