/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'link': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M115.6 128H.1V12.3h57.7v12.3H12.4v90.9h90.9V70.2h12.3z"/><path pid="1" d="M116.4 2.8l8.8 8.8-56.8 56.7-8.7-8.7z"/><path pid="2" d="M127.9 38h-12.4V12.4H88.7V0H128z"/>'
  }
});