import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Layout from '@/layout/index.vue';
import { pincodesSubMenu } from './subMenus/pincode-sub-menu';
import { userSubMenu } from './subMenus/user-sub-menu';
import { usersPaymentsSubMenu } from './subMenus/users-payments-sub-menu';
import { usersRolesSubMenu } from './subMenus/users-roles-sub-menu';
import { usersDocumentsSubMenu } from './subMenus/users-documents-sub-menu';
var customerRouter = {
  path: '/customer',
  component: Layout,
  redirect: 'noredirect',
  name: 'customerManagement.title',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'customerManagement.title',
    icon: 'peoples'
  },
  children: [{
    path: '/address/list/:id',
    component: function component() {
      return import('@/views/address/list.vue');
    },
    name: 'Address',
    meta: {
      title: 'customerManagement.address.title',
      hidden: true,
      noCache: true
    }
  }, {
    path: '/address/add/:id(\\d+)',
    component: function component() {
      return import('@/views/address/manage.vue');
    },
    name: 'customerManagement.address.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'customerManagement.address.add',
      noCache: true,
      hidden: true,
      activeMenu: '/address/list/:id'
    }
  }, {
    path: '/address/edit/:id(\\d+)',
    component: function component() {
      return import('@/views/address/manage.vue');
    },
    name: 'customerManagement.address.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'customerManagement.address.edit',
      noCache: true,
      hidden: true,
      activeMenu: '/address/list/:id'
    }
  }].concat(_toConsumableArray(pincodesSubMenu), _toConsumableArray(userSubMenu), _toConsumableArray(usersRolesSubMenu), _toConsumableArray(usersPaymentsSubMenu), _toConsumableArray(usersDocumentsSubMenu))
};
export default customerRouter;