import "core-js/modules/es.object.to-string";
export var transactionsSubMenu = [{
  path: '/transaction/list/',
  component: function component() {
    return import('@/views/transaction/list.vue');
  },
  name: 'Transaction',
  meta: {
    title: 'orderManagement.transaction.title',
    roles: ['superadmin', 'admin', 'Order Manager'],
    noCache: true,
    icon: 'inr'
  }
}, {
  path: '/transaction/list/:id(.*)',
  component: function component() {
    return import('@/views/transaction/list.vue');
  },
  name: 'Transaction',
  meta: {
    title: 'orderManagement.transaction.title',
    roles: ['superadmin', 'admin'],
    noCache: true,
    hidden: true
  }
}, {
  path: '/transaction/add/:id(.*)',
  component: function component() {
    return import('@/views/transaction/manage.vue');
  },
  name: 'orderManagement.transaction.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.transaction.add',
    noCache: true,
    activeMenu: '/transaction/list/:id(.*)',
    hidden: true
  }
}, {
  path: '/transaction/edit/:id(.*)',
  component: function component() {
    return import('@/views/transaction/manage.vue');
  },
  name: 'orderManagement.transaction.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.transaction.edit',
    noCache: true,
    activeMenu: '/transaction/list/:id(.*)',
    hidden: true
  }
}, {
  path: '/transaction/rtgs/:id(.*)',
  component: function component() {
    return import('@/views/transaction/rtgs.vue');
  },
  name: 'orderManagement.transaction.rtgs',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.transaction.rtgs',
    noCache: true,
    activeMenu: '/transaction/list/:id(.*)',
    hidden: true
  }
}];