import "core-js/modules/es.object.to-string";
export var bidsSubMenu = [{
  path: '/bid/list',
  component: function component() {
    return import('@/views/bid/list.vue');
  },
  name: 'Bid',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'orderManagement.bid.title',
    noCache: true,
    icon: 'auction'
  }
}, {
  path: '/bid/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/bid/manage.vue');
  },
  name: 'orderManagement.bid.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.bid.manage',
    noCache: true,
    activeMenu: '/bid/list',
    hidden: true
  }
}, {
  path: '/bid/add/',
  component: function component() {
    return import('@/views/bid/manage.vue');
  },
  name: 'orderManagement.bid.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.bid.manage',
    noCache: true,
    activeMenu: '/bid/list',
    hidden: true
  }
}];