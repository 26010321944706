import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var settingRouter = {
  path: '/setting',
  component: Layout,
  redirect: 'noredirect',
  name: 'settingManagement.title',
  meta: {
    title: 'settingManagement.title',
    roles: ['superadmin', 'admin'],
    icon: 'lock'
  },
  children: [// {
  //   path: '/setting/list',
  //   component: () => import('@/views/setting/list.vue'),
  //   name: 'settings',
  //   meta: {
  //     title: 'settingManagement.settings.title',
  //     noCache: true,
  //     icon: 'lock',
  //     hidden: true
  //   }
  // },
  {
    path: '/setting/info',
    component: function component() {
      return import('@/views/setting/info.vue');
    },
    name: 'settings',
    meta: {
      title: 'settingManagement.settings.title',
      noCache: true,
      icon: 'lock',
      hidden: true
    }
  }, {
    path: '/setting/edit/1',
    component: function component() {
      return import('@/views/setting/manage.vue');
    },
    name: 'settingManagement.settings.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'settingManagement.settings.title',
      noCache: true,
      activeMenu: '/setting/info',
      icon: 'lock'
    }
  }]
};
export default settingRouter;