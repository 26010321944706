import "core-js/modules/es.object.to-string";
export var usersRolesSubMenu = [{
  path: '/users-roles/list',
  component: function component() {
    return import('@/views/users-roles/list.vue');
  },
  name: 'userManagement.usersRoles.title',
  meta: {
    title: 'userManagement.usersRoles.title',
    icon: 'user',
    noCache: true
  }
}, {
  path: '/users-roles/add',
  component: function component() {
    return import('@/views/users-roles/manage.vue');
  },
  name: 'userManagement.usersRoles.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'userManagement.usersRoles.manage',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/users-roles/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/users-roles/manage.vue');
  },
  name: 'userManagement.usersRoles.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'userManagement.usersRoles.manage',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}];