import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var notificationsRouter = {
  path: '/notifications',
  component: Layout,
  redirect: 'noredirect',
  name: 'Notifications',
  meta: {
    title: 'notifications.title',
    roles: ['superadmin', 'admin'],
    icon: 'guide'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import(
      /* webpackChunkName: "admins-list" */
      '@/views/notifications/list.vue');
    },
    name: 'Notifications',
    meta: {
      title: 'notifications.title',
      noCache: true,
      icon: 'guide'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/notifications/manage.vue');
    },
    name: 'Edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'notifications.manage',
      noCache: true,
      activeMenu: '/notifications/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: function component() {
      return import('@/views/notifications/manage.vue');
    },
    name: 'Add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'notifications.manage',
      noCache: true,
      activeMenu: '/notifications/list',
      hidden: true
    }
  }]
};
export default notificationsRouter;