import "core-js/modules/es.object.to-string";
var campaignsRedeemsRouter = [{
  path: '/campaigns-redeems/list',
  component: function component() {
    return import('@/views/campaigns-redeems/list.vue');
  },
  name: 'campaignManagement.redeem.title',
  meta: {
    title: 'campaignManagement.redeem.title',
    noCache: true,
    icon: 'banner'
  }
}, {
  path: '/campaigns-redeems/add/',
  component: function component() {
    return import('@/views/campaigns-redeems/manage.vue');
  },
  name: 'campaignManagement.redeem.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'campaignManagement.redeem.add',
    noCache: true,
    activeMenu: '/campaigns-redeems/list',
    hidden: true
  }
}, {
  path: '/campaigns-redeems/edit/:id(.*)',
  component: function component() {
    return import('@/views/campaigns-redeems/manage.vue');
  },
  name: 'campaignManagement.redeem.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'campaignManagement.redeem.edit',
    noCache: true,
    activeMenu: '/campaigns-redeems/list',
    hidden: true
  }
}];
export default campaignsRedeemsRouter;