import "core-js/modules/es.object.to-string";
export var holidaySubMenu = [{
  path: '/holidays/list',
  component: function component() {
    return import(
    /* webpackChunkName: "admins-list" */
    '@/views/holidays/list.vue');
  },
  name: 'master.holiday.title',
  meta: {
    title: 'master.holiday.title',
    noCache: true,
    icon: 'list'
  }
}, {
  path: '/holidays/add',
  component: function component() {
    return import('@/views/holidays/manage.vue');
  },
  name: 'master.holiday.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.holiday.add',
    noCache: true,
    activeMenu: '/holidays/list',
    hidden: true
  }
}, {
  path: '/holidays/edit/:id(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "countries-list" */
    '@/views/holidays/manage.vue');
  },
  name: 'master.holiday.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.holiday.edit',
    noCache: true,
    activeMenu: '/holidays/list',
    hidden: true
  }
}];