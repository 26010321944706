import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Layout from '@/layout/index.vue';
import { bidsSubMenu } from './subMenus/bid-sub-menu';
import { transportersSubMenu } from './subMenus/transporter-sub-menu';
import { transactionsSubMenu } from './subMenus/transaction-sub-menu';
import { ordersDispatchesSubMenu } from './subMenus/orders-dispatches-sub-menu';
var orderRouter = {
  path: '/order',
  component: Layout,
  redirect: 'noredirect',
  name: 'orderManagement.title',
  meta: {
    roles: ['superadmin', 'admin', 'Order Manager'],
    title: 'orderManagement.title',
    icon: 'shopping'
  },
  children: [{
    path: '/order/list',
    component: function component() {
      return import('@/views/order/list.vue');
    },
    name: 'Orders',
    meta: {
      title: 'orderManagement.orders.title',
      icon: 'shopping',
      noCache: true
    }
  }, {
    path: '/order/pending-dispatch',
    component: function component() {
      return import('@/views/order/pending-dispatch.vue');
    },
    name: 'Pending Dispatch',
    meta: {
      title: 'orderManagement.orders.dispatch',
      icon: 'shopping',
      noCache: true
    }
  }, {
    path: '/order/edit/:id(.*)',
    component: function component() {
      return import('@/views/order/manage.vue');
    },
    name: 'orderManagement.orders.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.edit',
      roles: ['superadmin', 'admin', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/ratings/:id(.*)',
    component: function component() {
      return import('@/views/order/ratings.vue');
    },
    name: 'orderManagement.orders.manageRating',
    meta: {
      title: 'orderManagement.orders.manageRating',
      roles: ['superadmin', 'admin', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/modify/:id(.*)',
    component: function component() {
      return import('@/views/order/manage-order.vue');
    },
    name: 'orderManagement.orders.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.manage',
      roles: ['superadmin', 'admin', 'Order Manager'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/:id(.*)',
    component: function component() {
      return import('@/views/order/components/OrderInfoDetails.vue');
    },
    name: 'orderManagement.orders.info',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.orders.info',
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: '/order/add',
    component: function component() {
      return import('@/views/order/manage.vue');
    },
    name: 'orderManagement.orders.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'orderManagement.orders.add',
      roles: ['superadmin'],
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }].concat(_toConsumableArray(transactionsSubMenu), _toConsumableArray(bidsSubMenu), _toConsumableArray(transportersSubMenu), _toConsumableArray(ordersDispatchesSubMenu))
};
export default orderRouter;