import request from '@/utils/request';
export var defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  password: undefined,
  avatar: '',
  adminsRoleId: 1,
  adminsRole: {
    id: null,
    name: ''
  }
};
export var getAdmins = function getAdmins(params) {
  return request({
    url: '/admins',
    method: 'get',
    params: params
  });
};
export var getAdminById = function getAdminById(id) {
  return request({
    url: "/admins/".concat(id),
    method: 'get'
  });
};
export var getAdminInfo = function getAdminInfo(data) {
  return request({
    url: '/admins/me',
    method: 'post',
    data: data
  });
};
export var getAdminByEmail = function getAdminByEmail(adminemail) {
  return request({
    url: "/admins/".concat(adminemail),
    method: 'get'
  });
};
export var updateAdmin = function updateAdmin(id, data) {
  return request({
    url: "/admins/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteAdmin = function deleteAdmin(adminname) {
  return request({
    url: "/admins/".concat(adminname),
    method: 'delete'
  });
};
export var login = function login(data) {
  return request({
    url: '/admins/login',
    method: 'post',
    data: data
  });
};
export var logout = function logout() {
  return request({
    url: '/admins/logout',
    method: 'post'
  });
};
export var register = function register(data) {
  return request({
    url: '/admins/',
    method: 'post',
    data: data
  });
};