import "core-js/modules/es.object.to-string";
export var usersPaymentsSubMenu = [{
  path: '/users-payments/list/:userId(.*)',
  component: function component() {
    return import('@/views/users-payments/list.vue');
  },
  name: 'Payments',
  meta: {
    title: 'orderManagement.payment.title',
    roles: ['superadmin', 'admin'],
    noCache: true,
    hidden: true
  }
}, {
  path: '/users-payments/add/:userId(.*)',
  component: function component() {
    return import('@/views/users-payments/manage.vue');
  },
  name: 'orderManagement.payment.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.payment.add',
    noCache: true,
    activeMenu: '/users-payments/list/:userId(.*)',
    hidden: true
  }
}];