import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Layout from '@/layout/index.vue';
import { categoriesSubMenu } from './subMenus/category-sub-menu';
import { updSubMenu } from './subMenus/upd-sub-menu';
var productRouter = {
  path: '/product',
  component: Layout,
  redirect: 'noredirect',
  name: 'productManagement.title',
  meta: {
    roles: ['superadmin', 'admin', 'Product Manager'],
    title: 'productManagement.title',
    icon: 'almond'
  },
  children: [{
    path: '/brand/list',
    component: function component() {
      return import('@/views/brand/list.vue');
    },
    name: 'Brands',
    meta: {
      title: 'productManagement.brand.title',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/brand/edit/:id(\\d+)',
    component: function component() {
      return import('@/views/brand/manage.vue');
    },
    name: 'productManagement.brand.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.brand.manage',
      noCache: true,
      activeMenu: '/brand/list',
      hidden: true
    }
  }, {
    path: '/brand/add',
    component: function component() {
      return import('@/views/brand/manage.vue');
    },
    name: 'productManagement.brand.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.brand.manage',
      noCache: true,
      activeMenu: '/brand/list',
      hidden: true
    }
  }, {
    path: '/attribute/list',
    component: function component() {
      return import('@/views/attribute/list.vue');
    },
    name: 'productManagement.attribute.title',
    meta: {
      title: 'productManagement.attribute.title',
      roles: ['superadmin', 'admin'],
      noCache: true,
      icon: 'sack'
    }
  }, {
    path: '/attribute/add',
    component: function component() {
      return import('@/views/attribute/manage.vue');
    },
    name: 'productManagement.attribute.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.attribute.add',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/attribute/edit/:id(\\d+)',
    component: function component() {
      return import('@/views/attribute/manage.vue');
    },
    name: 'productManagement.attribute.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.attribute.edit',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/attribute-value/list/:id',
    component: function component() {
      return import('@/views/attribute-value/list.vue');
    },
    name: 'Attribute Value',
    meta: {
      title: 'productManagement.attributevalue.title',
      roles: ['superadmin', 'admin'],
      noCache: true,
      activeMenu: '/attribute/list',
      hidden: true
    }
  }, {
    path: '/attribute-value/add/:id(\\d+)',
    component: function component() {
      return import('@/views/attribute-value/manage.vue');
    },
    name: 'Manage Attribute Value',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.attributevalue.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/attribute-value/edit/:id(\\d+)',
    component: function component() {
      return import('@/views/attribute-value/manage.vue');
    },
    name: 'productManagement.attributevalue.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.attributevalue.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/product/list',
    component: function component() {
      return import('@/views/products/list.vue');
    },
    name: 'productManagement.product.title',
    meta: {
      title: 'productManagement.product.title',
      icon: 'almond',
      noCache: true
    }
  }, {
    path: '/product/add',
    component: function component() {
      return import('@/views/products/manage.vue');
    },
    name: 'productManagement.product.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'productManagement.product.manage',
      roles: ['superadmin', 'admin'],
      noCache: true,
      hidden: true
    }
  }, {
    path: '/product/edit/:id(\\d+)',
    component: function component() {
      return import('@/views/products/manage.vue');
    },
    name: 'productManagement.product.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'productManagement.product.manage',
      roles: ['superadmin', 'admin', 'Product Manager'],
      noCache: true,
      hidden: true
    }
  }].concat(_toConsumableArray(categoriesSubMenu), _toConsumableArray(updSubMenu))
};
export default productRouter;