import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.to-string";
import axios from 'axios';
import { Message } from 'element-ui';
import { AdminModule } from '@/store/modules/admin';
import Qs from 'qs';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000 // withCredentials: true // send cookies when cross-domain requests

}); // Request interceptors

service.interceptors.request.use(function (config) {
  // Add X-Access-Token header to every request, you can add other custom headers here
  if (AdminModule.token) {
    config.headers.Authorization = 'Bearer ' + AdminModule.token;
  } // if type get then serialize params


  if (config.method === 'get') {
    // check if filter exist
    if (config.params && config.params.filter && config.params.filter.length > 0) {
      config.paramsSerializer = function (params) {
        return Qs.stringify(params, {
          arrayFormat: 'repeat'
        });
      };
    }
  } else if (config.method === 'patch') {
    config.data.updatedBy = AdminModule.id;
  } else if (config.method === 'post' && config.data) {
    config.data.createdBy = AdminModule.id;
  }

  return config;
}, function (error) {
  Promise.reject(error);
}); // Response interceptors

service.interceptors.response.use(function (response) {
  // Some example codes here:
  // code == 20000: success
  // code == 50001: invalid access token
  // code == 50002: already login in other place
  // code == 50003: access token expired
  // code == 50004: invalid user (user not exist)
  // code == 50005: username or password is incorrect
  // You can change this part for your own usage.
  return response.data;
  /*
  if (res.code !== 20000) {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      MessageBox.confirm(
        'You have been logged out, you can cancel to stay on this page, or log in again',
        'Confirm logout',
        {
          confirmButtonText: 're-register',
          cancelButtonText: 'cancel',
          type: 'warning'
        }
      ).then(() => {
        AdminModule.ResetToken()
        location.reload() // To prevent bugs from vue-router
      })
    }
    return Promise.reject(new Error(res.message || 'Error'))
  } else {
    return response.data
  }
  */
}, function (error) {
  if (!error.response) {
    // network error
    var errorMessage = 'Network error!';
    Message({
      message: errorMessage,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(errorMessage);
  } else if (error.response) {
    // logout if status code is 403
    if (error.response.status === 403) {
      AdminModule.ResetToken();
      location.reload();
    } else if (error.response.data.message) {
      Message({
        message: error.response.data.message,
        type: 'error',
        duration: 5 * 1000
      });
    }
  }

  return Promise.reject(error.response.data);
});
export default service;