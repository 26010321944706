import "core-js/modules/es.object.to-string";
export var usersDocumentsSubMenu = [{
  path: '/users-documents/list',
  component: function component() {
    return import('@/views/users-documents/list.vue');
  },
  name: 'Documents',
  meta: {
    title: 'userManagement.document.title',
    roles: ['superadmin', 'admin'],
    noCache: true,
    icon: 'pdf'
  }
}, {
  path: '/users-documents/manage/:userId(.*)',
  component: function component() {
    return import('@/views/users-documents/manage.vue');
  },
  name: 'userManagement.document.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'userManagement.document.manage',
    noCache: true,
    activeMenu: '/users-documents/list',
    hidden: true
  }
}];