// Inspired by https://github.com/Inndy/vue-clipboard2
import Clipboard from 'clipboard';

if (!Clipboard) {
  throw new Error('you should npm install `clipboard` --save at first ');
}

var successCallback;
var errorCallback;
var clipboardInstance;
export var clipboard = {
  bind: function bind(el, binding) {
    if (binding.arg === 'success') {
      successCallback = binding.value;
    } else if (binding.arg === 'error') {
      errorCallback = binding.value;
    } else {
      clipboardInstance = new Clipboard(el, {
        text: function text() {
          return binding.value;
        },
        action: function action() {
          return binding.arg === 'cut' ? 'cut' : 'copy';
        }
      });
      clipboardInstance.on('success', function (e) {
        var callback = successCallback;
        callback && callback(e);
      });
      clipboardInstance.on('error', function (e) {
        var callback = errorCallback;
        callback && callback(e);
      });
    }
  },
  update: function update(el, binding) {
    if (binding.arg === 'success') {
      successCallback = binding.value;
    } else if (binding.arg === 'error') {
      errorCallback = binding.value;
    } else {
      clipboardInstance = new Clipboard(el, {
        text: function text() {
          return binding.value;
        },
        action: function action() {
          return binding.arg === 'cut' ? 'cut' : 'copy';
        }
      });
    }
  },
  unbind: function unbind(_, binding) {
    if (binding.arg === 'success') {
      successCallback = null;
    } else if (binding.arg === 'error') {
      errorCallback = null;
    } else {
      if (clipboardInstance) {
        clipboardInstance.destroy();
      }

      clipboardInstance = null;
    }
  }
};