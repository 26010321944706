var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("h3", { staticClass: "drawer-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("settings.title")) + " ")
          ]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.showSidebarTitle,
              callback: function($$v) {
                _vm.showSidebarTitle = $$v
              },
              expression: "showSidebarTitle"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.theme")))]),
          _c("theme-picker", {
            staticStyle: {
              float: "right",
              height: "26px",
              margin: "-3px 8px 0 0"
            },
            on: { change: _vm.themeChange }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.showTagsView")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.showTagsView,
              callback: function($$v) {
                _vm.showTagsView = $$v
              },
              expression: "showTagsView"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.showSidebarLogo")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.showSidebarLogo,
              callback: function($$v) {
                _vm.showSidebarLogo = $$v
              },
              expression: "showSidebarLogo"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.fixedHeader")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.fixedHeader,
              callback: function($$v) {
                _vm.fixedHeader = $$v
              },
              expression: "fixedHeader"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.sidebarTextTheme")))]),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.sidebarTextTheme,
              callback: function($$v) {
                _vm.sidebarTextTheme = $$v
              },
              expression: "sidebarTextTheme"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }