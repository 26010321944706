import "core-js/modules/es.object.to-string";
export var userSubMenu = [{
  path: '/users/list',
  component: function component() {
    return import('@/views/users/list.vue');
  },
  name: 'userManagement.users.title',
  meta: {
    title: 'userManagement.users.title',
    icon: 'user',
    noCache: true
  }
}, {
  path: '/users/add',
  component: function component() {
    return import('@/views/users/manage.vue');
  },
  name: 'userManagement.users.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'userManagement.users.add',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/users/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/users/manage.vue');
  },
  name: 'userManagement.users.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'userManagement.users.edit',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/users-wallet/list/:userId(\\d+)',
  component: function component() {
    return import('@/views/users/wallet-list.vue');
  },
  name: 'userManagement.wallet.title',
  meta: {
    title: 'userManagement.wallet.title',
    icon: 'user',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}, {
  path: '/users-wallet/add/:userId(\\d+)',
  component: function component() {
    return import('@/views/users/wallet-manage.vue');
  },
  name: 'userManagement.wallet.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'userManagement.wallet.manage',
    noCache: true,
    activeMenu: '/users/list',
    hidden: true
  }
}];