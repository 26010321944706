/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'language': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M84.7 36.8A44 44 0 0096 55.2c4.8-4.8 8-11.2 10.4-18.4H84.7zM32 76.8h20.8l-10.4-28-10.4 28z"/><path pid="1" d="M112 0H16A16 16 0 000 16v96a16 16 0 0016 16h96a16 16 0 0015.9-16V16c0-8.8-6.4-16-16-16zM72.7 103.2c-1.6 1.6-3.2 1.6-4.8 1.6-.8 0-2.4 0-3.2-.8-.8-.8-1.6 0-1.6-.8s-.8-1.6-1.6-3.2-.8-2.4-1.6-4l-3.2-8.8h-28L25.6 96c-1.6 3.2-2.4 5.6-3.2 7.2-.8 1.6-2.4 1.6-4.8 1.6-1.6 0-3.2-.8-4.8-1.6-1.6-1.6-2.4-2.4-2.4-4 0-.8 0-1.6.8-3.2s.8-2.4 1.6-4l17.6-44.8c.8-1.6.8-3.2 1.6-4.8.8-1.6 1.6-3.2 2.4-4 .8-.8 1.6-2.4 3.2-3.2 1.6-.8 3.2-.8 4.8-.8 1.6 0 3.2 0 4.8.8 1.6.8 2.4 1.6 3.2 3.2a39 39 0 014.8 9.6l17.6 44c1.6 3.2 2.4 5.6 2.4 7.2-.8.8-1.6 2.4-2.4 4zm44-31.2a64.6 64.6 0 01-20.9-12 47.5 47.5 0 01-21.5 12L72 68c8.7-2.4 16-5.6 21.5-11.2a42.1 42.1 0 01-12-20.8h-8v-3.2h21.6c-1.6-2.4-3.2-5.6-4.8-8l2.4-.8c1.6 2.4 4 5.6 5.6 8.8h20v4h-8c-2.4 8-6.4 15.2-11.2 20 5.6 4.8 12 8.8 20.8 11.2l-3.2 4z"/>'
  }
});