import "core-js/modules/es.object.to-string";
export var transportersSubMenu = [{
  path: '/transporters/list',
  component: function component() {
    return import('@/views/transporters/list.vue');
  },
  name: 'Transporter',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'orderManagement.transporter.title',
    noCache: true,
    icon: 'building'
  }
}, {
  path: '/transporters/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/transporters/manage.vue');
  },
  name: 'orderManagement.transporter.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'orderManagement.transporter.edit',
    noCache: true,
    activeMenu: '/transporters/list',
    hidden: true
  }
}, {
  path: '/transporters/add/',
  component: function component() {
    return import('@/views/transporters/manage.vue');
  },
  name: 'orderManagement.transporter.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'orderManagement.transporter.add',
    noCache: true,
    activeMenu: '/transporters/list',
    hidden: true
  }
}];