import "core-js/modules/es.object.to-string";
var bannerRouter = [{
  path: '/banner/list',
  component: function component() {
    return import('@/views/banner/list.vue');
  },
  name: 'banners',
  meta: {
    title: 'bannerManagement.banners.title',
    noCache: true,
    icon: 'banner'
  }
}, {
  path: '/banner/add/',
  component: function component() {
    return import('@/views/banner/manage.vue');
  },
  name: 'bannerManagement.banners.add',
  props: {
    isEdit: false
  },
  meta: {
    title: 'bannerManagement.banners.add',
    noCache: true,
    activeMenu: '/banner/list',
    hidden: true
  }
}, {
  path: '/banner/edit/:id(.*)',
  component: function component() {
    return import('@/views/banner/manage.vue');
  },
  name: 'bannerManagement.banners.edit',
  props: {
    isEdit: true
  },
  meta: {
    title: 'bannerManagement.banners.edit',
    noCache: true,
    activeMenu: '/banner/list',
    hidden: true
  }
}];
export default bannerRouter;