/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'location': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M334.224 391.424c0-95.952 79.728-173.968 177.776-173.968 98.048 0 177.776 78.016 177.776 173.968 0 95.936-79.728 173.968-177.776 173.968-98.048 0-177.776-78.032-177.776-173.968M512.448 0h-.896C291.232.224 112 175.744 112 391.424c0 109.424 88.928 265.024 176 391.424a2759.648 2759.648 0 00161.824 211.44A81.104 81.104 0 00512.048 1024h.128A80.512 80.512 0 00574 994.512a2756.736 2756.736 0 00162-211.68c87.072-126.384 176-281.984 176-391.408C912 175.744 732.752.224 512.448 0"/>'
  }
});