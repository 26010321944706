import "core-js/modules/es.object.to-string";
export var locationSubMenu = [{
  path: '/countries/list',
  component: function component() {
    return import(
    /* webpackChunkName: "countries-list" */
    '@/views/locations/countries/list.vue');
  },
  name: 'Countries',
  meta: {
    roles: ['superadmin'],
    title: 'locations.countries.title',
    noCache: true,
    icon: 'globe'
  }
}, {
  path: '/countries/edit/:id(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "countries-list" */
    '@/views/locations/countries/manage.vue');
  },
  name: 'Edit Country',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.countries.edit',
    noCache: true,
    activeMenu: '/countries/list',
    hidden: true
  }
}, {
  path: '/countries/add',
  component: function component() {
    return import('@/views/locations/countries/manage.vue');
  },
  name: 'Add Country',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.countries.add',
    noCache: true,
    activeMenu: '/countries/list',
    hidden: true
  }
}, {
  path: '/states/list/:countryId(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "states-list" */
    '@/views/locations/states/list.vue');
  },
  name: 'States',
  meta: {
    title: 'locations.states.title',
    noCache: true,
    hidden: true
  }
}, {
  path: '/states/edit/:id(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "states-list" */
    '@/views/locations/states/manage.vue');
  },
  name: 'Edit State',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.states.edit',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/states/add/:countryId(\\d+)',
  component: function component() {
    return import('@/views/locations/states/manage.vue');
  },
  name: 'Add State',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.states.add',
    noCache: true,
    activeMenu: '/states/list',
    hidden: true
  }
}, {
  path: '/cities/list/:stateId(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "cities-list" */
    '@/views/locations/cities/list.vue');
  },
  name: 'Cities',
  meta: {
    title: 'locations.cities.title',
    noCache: true,
    hidden: true
  }
}, {
  path: '/cities/edit/:id(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "cities-list" */
    '@/views/locations/cities/manage.vue');
  },
  name: 'Edit City',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.cities.edit',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, {
  path: '/cities/add/:stateId(\\d+)',
  component: function component() {
    return import('@/views/locations/cities/manage.vue');
  },
  name: 'Add City',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.cities.add',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, // VRL
{
  path: '/vrls/list/:cityId(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "cities-list" */
    '@/views/locations/cities/vrls/list.vue');
  },
  name: 'VRL Delivery Locations',
  meta: {
    title: 'locations.cities.vrls.title',
    noCache: true,
    hidden: true
  }
}, {
  path: '/vrls/edit/:id(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "cities-list" */
    '@/views/locations/cities/vrls/manage.vue');
  },
  name: 'Edit VRL',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.cities.vrls.edit',
    noCache: true,
    activeMenu: '/vrls/list',
    hidden: true
  }
}, {
  path: '/vrls/add/:cityId(\\d+)',
  component: function component() {
    return import('@/views/locations/cities/vrls/manage.vue');
  },
  name: 'Add VRL',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.cities.vrls.add',
    noCache: true,
    activeMenu: '/vrls/list',
    hidden: true
  }
}, // pincode
{
  path: '/pincode/list/:id',
  component: function component() {
    return import('@/views/pincode/list.vue');
  },
  name: 'Pincode',
  meta: {
    title: 'locations.pincode.title',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, {
  path: '/pincode/add/:cityId(\\d+)',
  component: function component() {
    return import('@/views/pincode/manage.vue');
  },
  name: 'Add Pincode',
  props: {
    isEdit: false
  },
  meta: {
    title: 'locations.pincode.add',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}, {
  path: '/pincode/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/pincode/manage.vue');
  },
  name: 'Edit Pincode',
  props: {
    isEdit: true
  },
  meta: {
    title: 'locations.pincode.edit',
    noCache: true,
    activeMenu: '/cities/list',
    hidden: true
  }
}];