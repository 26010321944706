/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'list': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M1.6 12c0 6.7 4 12 8.9 12s8.8-5.3 8.8-12c0-6.5-4-11.9-8.8-11.9-5 0-9 5.4-9 12zM125.9.2H35.6c-1.3 0-2.1 1.4-2.1 2.9v18.2c0 1.7 1 2.9 2.1 2.9H126c1.2 0 2-1.5 2-2.9V3c0-1.8-1-2.9-2-2.9zM0 63c0 6.6 4 12 8.9 12s8.9-5.4 8.9-12c0-6.7-4-12-9-12C4 51 0 56.3 0 63zm124-12H34c-1.2 0-2 1.4-2 2.8V72c0 1.7 1 2.8 2 2.8h90.3c1.2 0 2-1.4 2-2.8V53.7c0-1.4-.8-2.8-2-2.8zM0 116c0 6.6 4 12 8.9 12s8.9-5.4 8.9-12-4-12-9-12C4 104 0 109.4 0 116zm124-12H34c-1.2 0-2 1.5-2 2.9V125c0 1.8 1 2.9 2 2.9h90.3c1.2 0 2-1.4 2-2.9v-18.2c0-1.4-.8-2.9-2-2.9z"/>'
  }
});