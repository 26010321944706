import "core-js/modules/es.array.concat";
import _toConsumableArray from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Layout from '@/layout/index.vue';
import { taxSubMenu } from './subMenus/tax-sub-menu';
import { locationSubMenu } from './subMenus/location-sub-menu';
import { measurementUnitSubMenu } from './subMenus/measurement-unit-sub-menu';
import bannerRouter from './subMenus/banner-sub-menu';
import { holidaySubMenu } from './subMenus/holiday-sub-menu';
var masterRouter = {
  path: '/masters',
  component: Layout,
  redirect: 'noredirect',
  name: 'Masters',
  meta: {
    roles: ['superadmin', 'admin', 'Product Manager'],
    title: 'master.title',
    icon: 'star'
  },
  children: [].concat(_toConsumableArray(bannerRouter), _toConsumableArray(taxSubMenu), _toConsumableArray(locationSubMenu), _toConsumableArray(measurementUnitSubMenu), _toConsumableArray(holidaySubMenu))
};
export default masterRouter;