import "core-js/modules/es.object.to-string";
import Layout from '@/layout/index.vue';
var adminRouter = {
  path: '/admins',
  component: Layout,
  redirect: 'noredirect',
  name: 'Admin Management',
  meta: {
    title: 'adminManagement.title',
    roles: ['superadmin', 'admin'],
    icon: 'user'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import(
      /* webpackChunkName: "admins-list" */
      '@/views/admin/list.vue');
    },
    name: 'Admins',
    meta: {
      title: 'adminManagement.admins.title',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/admin/manage.vue');
    },
    name: 'Edit Admin',
    props: {
      isEdit: true
    },
    meta: {
      title: 'adminManagement.admins.manage',
      noCache: true,
      activeMenu: '/admin/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: function component() {
      return import('@/views/admin/manage.vue');
    },
    name: 'Add Admin',
    props: {
      isEdit: false
    },
    meta: {
      title: 'adminManagement.admins.manage',
      noCache: true,
      activeMenu: '/admin/list',
      hidden: true
    }
  }, {
    path: '/admin-roles/list',
    component: function component() {
      return import(
      /* webpackChunkName: "admins-roles" */
      '@/views/admin/admin-roles/list.vue');
    },
    name: 'Roles',
    meta: {
      title: 'adminManagement.roles.title',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/admin-roles/add',
    component: function component() {
      return import(
      /* webpackChunkName: "admins-roles" */
      '@/views/admin/admin-roles/manage.vue');
    },
    name: 'Add Role',
    props: {
      isEdit: false
    },
    meta: {
      title: 'adminManagement.roles.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/admin-roles/edit/:id(\\d+)',
    component: function component() {
      return import(
      /* webpackChunkName: "admins-roles" */
      '@/views/admin/admin-roles/manage.vue');
    },
    name: 'Edit Role',
    props: {
      isEdit: true
    },
    meta: {
      title: 'adminManagement.roles.manage',
      noCache: true,
      hidden: true
    }
  }]
};
export default adminRouter;