import "core-js/modules/es.object.to-string";
export var categoriesSubMenu = [{
  path: '/category/list/',
  component: function component() {
    return import('@/views/category/list.vue');
  },
  name: 'categories',
  meta: {
    title: 'productManagement.category.title',
    roles: ['superadmin', 'admin'],
    noCache: true,
    icon: 'tree'
  }
}, {
  path: '/category/list/:parentId(\\d+)',
  component: function component() {
    return import('@/views/category/list.vue');
  },
  name: 'categories',
  meta: {
    title: 'productManagement.category.title',
    noCache: true,
    hidden: true
  }
}, {
  path: '/category/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/category/manage.vue');
  },
  name: 'productManagement.category.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.category.manage',
    noCache: true,
    activeMenu: '/category/list',
    hidden: true
  }
}, {
  path: '/category/add/:parentId(\\d+)',
  component: function component() {
    return import('@/views/category/manage.vue');
  },
  name: 'productManagement.category.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.category.manage',
    noCache: true,
    activeMenu: '/category/list',
    hidden: true
  }
}];