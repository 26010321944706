import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.keys";
import _objectSpread from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLanguage } from '@/utils/cookies'; // element-ui built-in lang

import elementEnLocale from 'element-ui/lib/locale/lang/en'; // User defined lang

import enLocale from './en';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale)
};
export var getLocale = function getLocale() {
  var cookieLanguage = getLanguage();

  if (cookieLanguage) {
    SetLanguageDirection(cookieLanguage);
    return cookieLanguage;
  }

  var language = navigator.language.toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  } // Default language is english


  return 'en';
};
export var SetLanguageDirection = function SetLanguageDirection() {
  var language = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var body = document.querySelector('body');

  if (body) {
    if (language === 'ar') {
      if (!body.classList.contains('rtl')) {
        body.classList.add('rtl');
      }
    } else {
      body.classList.remove('rtl');
    }
  }
};
var i18n = new VueI18n({
  locale: getLocale(),
  messages: messages
});
export default i18n;