import "core-js/modules/es.object.to-string";
export var updSubMenu = [{
  path: '/users-products-discounts/list',
  component: function component() {
    return import('@/views/users-products-discounts/list.vue');
  },
  name: 'Bazar Dhara',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'productManagement.discount.title',
    noCache: true,
    icon: 'inr'
  }
}, {
  path: '/users-products-discounts/edit/:id(\\d+)',
  component: function component() {
    return import('@/views/users-products-discounts/manage.vue');
  },
  name: 'productManagement.discount.manage',
  props: {
    isEdit: true
  },
  meta: {
    title: 'productManagement.discount.manage',
    noCache: true,
    activeMenu: '/users-products-discounts/list',
    hidden: true
  }
}, {
  path: '/users-products-discounts/add/',
  component: function component() {
    return import('@/views/users-products-discounts/manage.vue');
  },
  name: 'productManagement.discount.manage',
  props: {
    isEdit: false
  },
  meta: {
    title: 'productManagement.discount.manage',
    noCache: true,
    activeMenu: '/users-products-discounts/list',
    hidden: true
  }
}];