import "core-js/modules/es.object.to-string";
export var taxSubMenu = [{
  path: '/tax/list',
  component: function component() {
    return import(
    /* webpackChunkName: "admins-list" */
    '@/views/master/tax/list.vue');
  },
  name: 'tax',
  meta: {
    title: 'master.tax.title',
    noCache: true,
    icon: 'tax'
  }
}, {
  path: '/tax/add',
  component: function component() {
    return import('@/views/master/tax/manage.vue');
  },
  name: 'Add Tax',
  props: {
    isEdit: false
  },
  meta: {
    title: 'master.tax.manage',
    noCache: true,
    activeMenu: '/tax/list',
    hidden: true
  }
}, {
  path: '/tax/edit/:id(\\d+)',
  component: function component() {
    return import(
    /* webpackChunkName: "countries-list" */
    '@/views/master/tax/manage.vue');
  },
  name: 'Edit Tax',
  props: {
    isEdit: true
  },
  meta: {
    title: 'master.tax.manage',
    noCache: true,
    activeMenu: '/tax/list',
    hidden: true
  }
}];