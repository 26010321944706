/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'drag': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M80 304h352v16H80zM80 248h352v16H80zM80 192h352v16H80z"/>'
  }
});