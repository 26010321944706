import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import _toConsumableArray from "/Users/sandeshbhoir/Documents/vue/biggest-supplier-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Layout from '@/layout/index.vue';
import campaignsRedeemsRouter from './subMenus/campaigns-redeems-sub-menu';
var campaignsRouter = {
  path: '/campaigns',
  component: Layout,
  redirect: 'noredirect',
  name: 'Reward Management',
  meta: {
    title: 'campaignManagement.reward.title',
    icon: 'shopping'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/campaigns/list.vue');
    },
    name: 'Rewards',
    meta: {
      title: 'campaignManagement.reward.title',
      icon: 'star',
      noCache: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/campaigns/manage.vue');
    },
    name: 'Edit Reward',
    props: {
      isEdit: true
    },
    meta: {
      title: 'campaignManagement.reward.edit',
      noCache: true,
      activeMenu: '/campaigns/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: function component() {
      return import('@/views/campaigns/manage.vue');
    },
    name: 'Add Reward',
    props: {
      isEdit: false
    },
    meta: {
      title: 'campaignManagement.reward.add',
      noCache: true,
      activeMenu: '/campaigns/list',
      hidden: true
    }
  }, {
    path: '/campaigns/redeems/:id',
    component: function component() {
      return import('@/views/campaigns/redeems.vue');
    },
    name: 'Campaign Redeems',
    meta: {
      title: 'campaignManagement.redeem.title',
      hidden: true,
      noCache: true,
      activeMenu: '/campaigns/list'
    }
  }].concat(_toConsumableArray(campaignsRedeemsRouter))
};
export default campaignsRouter;