var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExternal(_vm.to)
    ? _c(
        "a",
        { attrs: { href: _vm.to, target: "_blank", rel: "noopener" } },
        [_vm._t("default")],
        2
      )
    : _c("router-link", { attrs: { to: _vm.to } }, [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }